$primaryTextColor: #0078D4;
$primaryThemeColor: #223B54;
$secondaryTextColor: #201F1E;

$primaryHoverTextColor: #adddff;
$primaryButtonsColor: #EDEDED;

$infoColorText: #7e7e7e;

$primaryInputsBorderColor: #EDEBE9;


$primaryThemeColor: #223B54;

// texts
$primaryTextColor: #0078D4;
$primaryHoverTextColor: #adddff;
$infoColorText: #7e7e7e;
$secondColorText: #605E5C;
$secondOptionColor: #EDEBE9;
$titleColor: #323130;

// buttons
$primaryButtonsColor: #EDEDED;
$secondaryButtonsColor: #F7FBFE;
$disabledBtnColor: gray;

// elements
$borderColor: #EDEBE9;
$dropZoneBackgroundColor: #FAFAFA;

// table
$gridHeaderColor: #D3D6DF;

// notifications
$errorMessageColor: red;



