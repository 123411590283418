// fix the useless scrollbar in grid which has position into kendo splitter
.k-grid-container {
  .k-virtual-content {
    overflow: hidden;
  }
}
.k-grid-table-wrap {
  .k-grid-norecords {
    height: 45px;
  }
}

.k-grid .k-table-th:focus, .k-grid .k-table-td:focus {
  box-shadow: none !important;
}

thead.k-table-thead {
  background-color: #F2F4F5 !important;
}
.k-table-td {
  border-bottom: 1px solid #F2F4F5 !important;
}
.k-grid-content .k-table-row:last-child > td, .k-grid-content .k-table-row:last-child > .k-table-td, .k-grid-content-locked .k-table-row:last-child > td, .k-grid-content-locked .k-table-row:last-child > .k-table-td {
  border-bottom: 1px solid #F2F4F5 !important;
}
.k-table-th.k-sorted {
  background-color: inherit !important;
}
