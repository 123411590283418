.cssloadContainer {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.spinnerPosition {
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 163px;
  height: 163px;
  margin: 0 auto;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
