.modalActionBar {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;
  button {
    margin-left: 20px;
  }
}
