@import '../../../styles/variables';
@import '../../../styles/components/buttons';

.subHeaderBox {
  border-bottom: 1px solid #EDEBE9;
  margin: 10px 20px;
  padding-bottom: 10px;
  box-sizing: border-box;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.navigationTitles {
  color: #323130;
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
}
