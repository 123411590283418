@import '../variables';

.actionButton {
  border: 1px solid black;
  padding: 8px 20px;
  background-color: white;
  cursor: pointer;
  outline: none;
  height: 34px;
  align-items: center;
  display: flex;

  &:disabled {
    cursor: not-allowed;
    background-color: lightgray;
  }

  &:not(:disabled):hover {
    filter: brightness(85%);
  }

  &:focus,
  &.focus {
    outline: none;
    box-shadow: none !important;
  }
}

.buttonIconPosition {
  position: relative;
  left: -5px;
  height: 10px;
}

.actionButtonGray {
  @extend .actionButton;
  border-color: $borderColor;
}

.actionMainButton {
  @extend .actionButton;
  background-color: $primaryTextColor;
  color: white;
  border: none;
}

.secondaryButton {
  @extend .actionButton;
  background-color: $secondaryButtonsColor;
  color: $titleColor;
  border: none;
}
