@import '../../../styles/variables';

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.appBar {
  a {
    display: flex;
  }
}

.logo {
  display: flex;
  height: 60px;
  pointer-events: none;
}

.logoDesc {
  display: flex;
  font-size: 14px;
  color: white;
  font-weight: 500;
  align-items: center;
  width: 100px;
}

.headerRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

button.link,
a.link {
  color: rgba(255, 255, 255, 0.55);

  &:hover {
    color: rgba(255, 255, 255, 0.75);
  }
}

.menuItems {
  cursor: pointer;
  a.link {
    margin-right: 20px;
    text-decoration: none;
  }
}

:global {
  :local(.appBar) {
    width: 100%;
    padding: 10px;

    ul {
      font-size: 14px;
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
    }

    li {
      margin: 0 10px;
    }

    li:hover {
      cursor: pointer;
    }

    a {
      text-decoration: none;
    }

    a.active {
      color: $primaryTextColor;
    }

    .k-split-button-arrow {
      .k-button-icon {
        &::after {
          all: initial;
        }
      }
    }
  }
}

.dropdownIcon {
  margin-right: 10px;

  span:first-child {
    position: absolute;
    right: 1px;
    bottom: 0.7vh;
  }
}

:global {
  :local(.dropdownPopup) {
    & .k-item.k-focus {
      & .k-link {
        outline-style: none;
      }
    }
  }
}
