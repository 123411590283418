.page {
    padding: 10px 25px 10px 25px;
    position: relative;
    width: 100%;

    >.spinner {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .page-header {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        height: 28px;

        margin-top: 15px;
        margin-bottom: 25px;
    }

    .section-header {
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;

        margin-top: 15px;
        margin-bottom: 15px;
    }
}